import { type Component, Show, onMount } from "solid-js";
import { useCheckoutContext, useSessionContext } from "~/utils/contexts";
import { numberWithCommas } from "~/utils/helpers";
import Button from "~/components/Button";
import { Icon } from "solid-heroicons";
import { questionMarkCircle } from "solid-heroicons/outline";

export const CheckoutOrderSummary: Component<{ hideButton?: boolean }> = (
  props
) => {
  const { aCartActionIsPending } = useSessionContext();
  const {
    cart,
    handlePlaceOrder,
    isReviewValid,
    BPSContainsNonCorner,
    disableCheckoutButtons,
  } = useCheckoutContext();

  // onMount(() => {
  //   setSending(false);
  // });
  return (
    <div class="flex flex-col divide-y">
      <div class="flex flex-col justify-evenly gap-3 font-medium py-3">
        <div class="flex justify-between items-center text-sm">
          <p>Subtotal</p>
          <p>
            $
            {(
              (cart()?.Subtotal ?? 0) + Math.abs(cart()?.Discount ?? 0)
            ).toFixed(2)}
          </p>
        </div>
        <Show when={Math.abs(cart()?.Discount ?? 0) > 0}>
          <div class="flex justify-between items-center text-sm">
            <p>You saved</p>
            <p>(${Math.abs(cart()?.Discount ?? 0).toFixed(2)})</p>
          </div>
        </Show>
        {/* START Tariff Logic */}
        <Show
          when={cart()?.TariffCharge}
          // when={true}
        >
          <details>
            <summary class="flex justify-between">
              <div class="text-sm flex items-center ">
                <span class="inline-block">Tariff Fee</span>
                <Icon path={questionMarkCircle} class="ml-1 w-4 h-4 inline" />
              </div>
              <p class="text-sm">${(cart()?.TariffCharge || 0).toFixed(2)}</p>
            </summary>
            <p class="text-xs mt-2">
              Due to current tariff regulations, items shipped from Canada to
              the US will include a tariff fee.
            </p>
          </details>
        </Show>
        {/* END Tariff Logic */}
        <div class="flex justify-between items-center text-sm">
          <p>Tax</p>
          <p>${cart()?.Tax.toFixed(2)}</p>
        </div>
      </div>
      <div class="py-3">
        <div class="flex justify-between items-center text-base font-bold">
          <p>Total</p>
          <p>${numberWithCommas(cart()?.Amount.toFixed(2))}</p>
        </div>
        <Show when={!props.hideButton}>
          <div class="pt-8">
            <Button
              onClick={handlePlaceOrder}
              class="w-full"
              disabled={disableCheckoutButtons()}
            >
              Place Order
            </Button>
          </div>
        </Show>
      </div>
    </div>
  );
};
